// import store from "@/store/store";
export const routes = [
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/AdminView.vue"),
    meta: {
      isNeedAuth: true,
      hideComponents: true,
    },
    children: [
      {
        path: "products",
        name: "admin-products",
        component: () => import("@/components/admin/AdminProducts.vue"),
      },
      {
        path: "products/new-product",
        name: "admin-product-page-new",
        component: () => import("@/views/AdminProductPage.vue"),
        meta: {
          isNewProductPage: true,
        },
      },
      {
        path: "products/:article",
        name: "admin-product-page",
        component: () => import("@/views/AdminProductPage.vue"),
      },
      {
        path: ":url",
        name: "admin-data-list",
        component: () => import("@/components/admin/AdminDataList.vue"),
        props: (route) => ({ dataType: route.params.url }),
      },
    ],
  },
  {
    path: "/",
    name: "production",
    component: () => import("@/views/ProductionView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  // {
  //    path: "/home",
  //    name: "home",
  //    component: () => import("@/views/HomeView.vue"),
  //    meta: {
  //       isNeedAuth: true,
  //    },
  // },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/ConstructorView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/FaqView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () => import("@/views/FeedbackView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/guarantee",
    name: "guarantee",
    component: () => import("@/views/GuaranteeView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import("@/views/information/ContactsView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/policy",
    name: "policy",
    component: () => import("@/views/information/PolicyView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/information/TermsView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/refund",
    name: "refund",
    component: () => import("@/views/information/RefundView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/ordering",
    name: "ordering",
    component: () => import("@/views/OrderingView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/success",
    name: "success",
    component: () => import("@/views/SuccessView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  // {
  //    path: "/failed",
  //    name: "failed",
  //    component: () => import("@/views/FailedView.vue"),
  // },
  {
    path: "/collections/",
    name: "collections",
    component: () => import("@/views/CollectionsView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/collections/subcollections/:collection",
    name: "subcollections-page",
    component: () => import("@/views/CollectionsView.vue"),
    props: (route) => ({
      route_query: {
        ...route.query,
        // collection: route.query.collection,
      },
    }),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/products/",
    name: "products",
    component: () => import("@/views/ProductsView.vue"),
    meta: {
      isNeedAuth: true,
    },
    children: [
      // {
      //    path: "categories/:category",
      //    name: "products-categories",
      //    component: () => import("@/views/ProductsView.vue"),
      // },
      {
        path: "collections/:collection",
        name: "products-collection",
        component: () => import("@/views/ProductsView.vue"),
      },
      {
        path: "subcollections/:subcollection",
        name: "products-subcollection",
        component: () => import("@/views/ProductsView.vue"),
      },
    ],
  },
  {
    path: "/products/:article",
    name: "product-page",
    component: () => import("@/views/ProductPageView.vue"),
    meta: {
      isNeedAuth: true,
    },
  },

  {
    path: "/galleries/:gallery",
    name: "gallery-page",
    component: () => import("@/views/Gallery.vue"),
    meta: {
      isNeedAuth: true,
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/RedirectView"),
  },
  { path: "/:catchAll(.*)", redirect: "/404" },
];
